@import "../../../../../styles/variables";

.coolcat-home-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .app-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 90%;

        .logo {
            max-width: 100px;
            max-height: 100px;
            margin-right: 20px;
        }
    }

    .main-content {
        width: 90%;
        max-width: 1000px;
        margin-bottom: 50px;
        text-align: justify;

        .doc-link {
            display: flex;
            flex-direction: column;
            a {
                color: inherit;
            }
        }

        .store-link {
            a {
                margin-right: 10px;
                img {
                    width: 150px;
                }
            }
        }
    };   
}

