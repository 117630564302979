@import "../../../../styles/variables";

.promotion-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-content {
        width: 90%;
        max-width: 1100px;
        margin-top: 20px;
        margin-bottom: 50px;

        .promotion-item {
            border-top: 1px solid #ccc;
            padding-top: 15px;
        }

    }
}