@import "../../styles/variables";

.footer {
    background-color: $secondaryColor;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .brand {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                height: 45px;
            }

            p {
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                margin: 0;
            }
        }

        .sns {
            margin-left: 30px;
        }


    }


}