@import "../../../../styles/variables";

.apps-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-content {
        width: 90%;
        max-width: 1100px;
        margin-top: 20px;
        margin-bottom: 50px;

        .app-categories {
            .app-category-detail {
                .app-list {
                    display: flex;
                    flex-wrap: wrap;

                    .app-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 15px;
                        margin-bottom: 15px;
                        text-decoration: none;
                        color: inherit;
                        flex-basis: 49%;

                        .logo {
                            max-width: 100px;
                            max-height: 100px;
                            margin-right: 20px;
                        }
                    }

                    @media screen and (max-width: 1000px) {
                        .app-item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

    }
}