@import "../../../../styles/variables";

.about-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-content {
        width: 90%;
        max-width: 1000px;
        margin-top: 20px;
        margin-bottom: 50px;
        text-align: justify;
    };   
}

