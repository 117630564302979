@import "../../../../styles/variables";

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .banner-image {
        width: 100%;
        height: calc((100vw - 240px)/6);
    }

    .main-content {
        width: 90%;
        max-width: 1000px;
        margin-bottom: 50px;

        .intro-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #ccc;
            // text-align: justify;

            a {
                color: #000;
                margin-top: 10px;
                // text-decoration: none;
            }
        }

        .latest-release {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            h3 {
                text-decoration: underline;
                align-self: flex-start;
            }

            .latest-release-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 15px;
                margin-bottom: 15px;
                text-decoration: none;
                color: inherit;
                flex-basis: 49%;

                .logo {
                    max-width: 100px;
                    max-height: 100px;
                    margin-right: 20px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .banner-image {
            height: 18vw;
        }
    }
}